import './App.css';
import eventListeners from './event-listeners'
import {ReactComponent as Instagram} from './svgs/instagram.svg'
import {ReactComponent as Twitter} from './svgs/twitter.svg'
import {ReactComponent as YouTube} from './svgs/youtube.svg'
import {ReactComponent as Bandsintown} from './svgs/bandsintown.svg'
import {ReactComponent as Patreon} from './svgs/patreon.svg'

import testify from './preorder-billy-wayne-davis-testify-comedy-premiere-moment.webp'

import VideoPage from "./components/VideoPage";

function App() {

  eventListeners()

  return (
    <div className="App">
      <header className="App-header">

        <div id="messages"></div>
        <div id="comedySpecials" className="comedySpecials"><VideoPage /></div>
        <div id="tourdates" className="tourdates">
        
          <div className="bit-widget-initializer" 
          data-artist-name="id_5364132"
          
          data-background-color="#ffffff"
          data-separator-color="#DDDDDD"
          data-text-color="#fff"
          data-font="Helvetica"
          data-auto-style="false"
          
          data-display-local-dates="true"
          data-local-dates-position="tab"
          data-display-past-dates="false"
          data-display-details="false"
          data-display-lineup="false"
          data-display-start-time="false"
          data-social-share-icon="true"
          data-display-limit="all"
          
          data-date-format="MMM. D, YYYY"
          data-date-orientation="horizontal"
          data-date-border-color="#08C3BA"
          data-date-border-width="1px"
          data-date-border-radius="10px"
          
          data-event-ticket-cta-size="medium"
          data-event-ticket-text="TICKETS"
          data-event-ticket-icon="false"
          data-event-ticket-cta-text-color="#FFFFFF"
          data-event-ticket-cta-bg-color="#08C3BA"
          data-event-ticket-cta-border-color="#08C3BA"
          data-event-ticket-cta-border-width="0px"
          data-event-ticket-cta-border-radius="4px"
          
          data-sold-out-button-text-color="#FFFFFF"
          data-sold-out-button-background-color="#9B9B9B"
          data-sold-out-button-border-color="#08C3BA"
          data-sold-out-button-clickable="true"
          
          data-event-rsvp-position="left"
          data-event-rsvp-cta-size="medium"
          data-event-rsvp-text="SET REMINDER"
          data-event-rsvp-icon="false"
          data-event-rsvp-cta-text-color="#000"
          data-event-rsvp-cta-bg-color="#FFFFFF"
          data-event-rsvp-cta-border-color="#08C3BA"
          data-event-rsvp-cta-border-width="1px"
          data-event-rsvp-cta-border-radius="4px"
          
          data-follow-section-position="top"
          data-follow-section-alignment="center"
          data-follow-section-header-text="Get updates on new shows."
          data-follow-section-cta-size="medium"
          data-follow-section-cta-text="FOLLOW"
          data-follow-section-cta-icon="true"
          data-follow-section-cta-text-color="#FFFFFF"
          data-follow-section-cta-bg-color="#08C3BA"
          data-follow-section-cta-border-color="#08C3BA"
          data-follow-section-cta-border-width="0px"
          data-follow-section-cta-border-radius="4px"
          
          data-play-my-city-position="bottom"
          data-play-my-city-alignment="Center"
          data-play-my-city-header-text="Don’t see a show near you?"
          data-play-my-city-cta-size="medium"
          data-play-my-city-cta-text="REQUEST A SHOW"
          data-play-my-city-cta-icon="true"
          data-play-my-city-cta-text-color="#FFFFFF"
          data-play-my-city-cta-bg-color="#08C3BA"
          data-play-my-city-cta-border-color="#08C3BA"
          data-play-my-city-cta-border-width="0px"
          data-play-my-city-cta-border-radius="4px"
          
          data-language="en"
          data-widget-width=""
          data-div-id=""
          data-app-id=""
          data-affil-code="undefined"
          data-bit-logo-position="bottomRight"
          data-bit-logo-color="#CCCCCC"
          >
            <div></div>
          </div>

        </div>
        <div id="booking">
          <div>
          <ul>
            <li></li>
            <li>For Booking Inquiries</li>
            <li><a href="mailto:bookingbwd@gmail.com">bookingbwd@gmail.com</a></li>
          </ul>
          </div>
        </div>
        <div id="testify">
          <div >
            PRE-ORDER NOW!
          </div>
          <a href="https://800PGR.lnk.to/TestifyWE" title="Pre-Order comedian Billy Wayne Davis live comedy special watchparty on Moment with 800 Pound Gorilla Records" alt="Billy Wayne Davis new comedy special Testify available for pre-order">
          <img src={testify} alt="Billy Wayne Davis new comedy special Testify available everywhere! Watch on YouTube, Amazon and more"></img></a>
          <a href="https://800PGR.lnk.to/TestifyWE" title="Pre-Order comedian Billy Wayne Davis live comedy special watchparty on Moment with 800 Pound Gorilla Records">bwdtour.com/testify</a>
        </div>
        <div id="privacy" data-nosnippet>
          <p>"There is no privacy. I have already sold your information, and I will do it again!" <br />- Billy Wayne</p>
          <p className="longtext">But seriously, the normal internet'ing happens here, and cookies, log files, and web beacons (pixels, tags, etc) may be in use by us or
          third party services (see below) for the collection of data. This data may include information about your device, web browser,
          IP address, time zone, and/or some of the cookies installed on your device. For a better understanding of cookies, we recommend
          http://www.allaboutcookies.org

          You may also wish to consult the Digital Advertising Alliance's opt-out portal:
          http://optout.aboutads.info/
          As well as opt-out options from these providers:

          FACEBOOK Opt out of targeted advertising: https://www.facebook.com/settings/?tab=ads

          GOOGLE: https://www.google.com/settings/ads/anonymous

          BING: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads

          </p>
          <span>Services stealing your secrets while you're here:</span>
          <ul>
            <li>Bandsintown: <a href="https://corp.bandsintown.com/privacy">privacy policy</a></li>
            <li>Google Analytics (via Bandsintown): <a href="https://www.google.com/intl/en/policies/privacy/">privacy policy</a></li>
          </ul>
        </div>
        <div id="socials" className="socials">
          <ul>
            <li id="instagram" className="icon-link" aria-label="follow Billy Wayne Davis on Instagram"><Instagram /></li>
            <li id="twitter" className="icon-link" aria-label="follow Billy Wayne Davis on Twitter"><Twitter /></li>
            <li id="youtube" className="icon-link" aria-label="follow Billy Wayne Davis on YouTube"><YouTube /></li>
            <li id="bandsintown" className="icon-link" aria-label="Subscribe to Billy Wayne Davis on Band In Town"><Bandsintown /></li>
            <li id="patreon" className="icon-link" aria-label="Support Billy Wayne Davis on Patreon"><Patreon /></li>
          </ul>
        </div>
        <footer className="footer">
          <div id="nav-footer">
            <ul>
              <li id="footer-comedy-specials" aria-label="Billy Wayne Davis stand up comedy specials Testify and Live at Third Man Records">Stand Up Specials</li>
              <li id="footer-testify" aria-label="Billy Wayne Davis comedy special Testify">TESTIFY</li>
              <li id="footer-tour" aria-label="See Billy Wayne Davis Tour Dates">Tour Dates</li>
              <li id="footer-booking" aria-label="Booking Contact info for Billy Wayne Davis agent and manager">Booking</li>
              <li id="footer-privacy" aria-label="website privacy statement">Privacy</li>
            </ul>
          </div>
          <div>&copy; 2022 Billy Wayne Davis</div>
        </footer>
      </header>

    </div>
  );
}

export default App;
